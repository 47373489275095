import { h, createRef, Component } from 'preact';
import style from './style.less';

const getTeamEncryptedId = async (widgetId) => {
  const response = await fetch(`${process.env.API_URL}/team_info?widget_id=${widgetId}&limit=3`);
  const data = await response.json();

  return data.team.encrypted_id;
};

export default class IFrameApp extends Component {
  ref = createRef();

  componentDidMount() {
    const { widgetId } = this.props;
    getTeamEncryptedId(widgetId)
      .then(encryptedId => {
        const interval = setInterval(() => {
          this.ref.current.contentWindow.postMessage(encryptedId, '*');
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
        }, 10000);
      })
      .catch(console.error);
  }

  render() {
    const { widgetId } = this.props;
    return <iframe ref={this.ref} src={ `${process.env.PATIENT_CLIENT_URL}#/widget/${widgetId}` } className={ style.iframe } ></iframe>;
  }
}
